<template>
    <div id="marking-setting-content">
      <el-card shadow="always">
        <div id="title-box">
          <span class="main-title">{{ mainTitle }}</span>
          <span class="sub-title">{{ subTitle }}</span>
        </div>
        <p class="badge-font-bold">评卷规则</p>
  
        <el-row>
          <el-col :span="7" :offset="1">
            <div>
              <el-form ref="markRuleResult" :model="markRuleResult">
                <el-form-item label="评卷方式：" class="custom-label-weight">
                  <el-radio-group
                    v-model="markRuleResult.markType"
                    @change="markTypeChange"
                  >
                    <el-radio
                      v-for="markType in markTypeOptions"
                      :key="markType.value"
                      :label="markType.value"
                      disabled
                      >{{ markType.label }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  v-if="markRuleResult.markType != 1"
                  label="评卷规则："
                  class="custom-label-weight"
                >
                  <span v-if="markRuleResult.markType == 2"
                    >每位老师评阅的考生数（至多4名）为</span
                  >
                  <span v-else-if="markRuleResult.markType == 3"
                    >每位老师评阅的考生数（至多2名）为</span
                  >
                  <el-input
                    v-model="markRuleResult.sceneMarkNum"
                    placeholder
                    v-if="markRuleResult.markType == 2"
                    @input="
                      (e) =>
                        (this.markRuleResult.sceneMarkNum = $verifyInput(
                          e,
                          0,
                          4,
                          1
                        ))
                    "
                  ></el-input>
                  <el-input
                    v-model="markRuleResult.sceneMarkNum"
                    placeholder
                    v-if="markRuleResult.markType == 3"
                    @input="
                      (e) =>
                        (this.markRuleResult.sceneMarkNum = $verifyInput(
                          e,
                          0,
                          2,
                          1
                        ))
                    "
                  ></el-input>
                </el-form-item>
  
                <el-form-item
                  label="及格规则："
                  class="custom-label-weight"
                  prop="markPass"
                  v-if="0"
                >
                  <span>及格分数为</span>
                  <el-input
                    v-model="markRuleResult.markPass"
                    placeholder
                    @input="
                      (e) =>
                        (markRuleResult.markPass = $verifyInput(
                          e,
                          0,
                          markRule.markPassMax
                        ))
                    "
                  ></el-input>
                </el-form-item>
  
                <el-form-item
                  label="考生匹配规则："
                  class="custom-label-weight"
                  v-if="markRuleResult.markType == 1"
                >
                  <el-radio-group v-model="markRuleResult.matchRule">
                    <el-radio
                      v-for="markMatchRule in markMatchRuleOptions"
                      :key="markMatchRule.value"
                      :label="markMatchRule.value"
                      >{{ markMatchRule.label }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="5" :offset="2" class="flex-box">
            <div v-if="markRuleResult.markType == 1">
              <el-form ref="markRuleResult" :model="markRuleResult">
                <el-form-item label="评分规则：" class="custom-label-weight">
                  <el-radio
                    v-model="markRuleResult.markRule"
                    :label="2"
                    >多评制</el-radio
                  >
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <i
                      class="el-icon-warning-outline"
                      style="position: relative; left: -25px"
                    ></i>
                    <div slot="content" class="tooltipBox">
                      <span>{{ moreRuleDesc }}</span>
                    </div>
                  </el-tooltip>
                  <el-radio
                    v-model="markRuleResult.markRule"
                    :label="1"
                    >单评制</el-radio
                  >
                </el-form-item>
  
                <el-form-item
                  v-if="markRuleResult.markRule == 2"
                  label="多评规则"
                  class="custom-label-weight"
                >
                  <span>分差阈值为</span>
                  <el-input
                    v-model="markRuleResult.markThreshold"
                    placeholder
                    @input="
                      (e) =>
                        (markRuleResult.markThreshold = $verifyInput(
                          e,
                          0,
                          100,
                          1
                        ))
                    "
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="8" :offset="1">
            <div v-if="markRuleResult.markType == 1" style="margin-left: 80px">
              <el-form ref="markRuleResult" :model="markRuleResult">
                <el-form-item label="回评审查：" class="custom-label-weight">
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <i
                      class="el-icon-warning-outline"
                      style="position: relative; left: -10px"
                    ></i>
                    <div slot="content" class="tooltipBox">
                      <span>{{ reviewStatusDesc }}</span>
                    </div>
                  </el-tooltip>
                  <el-radio
                    v-model="reviewStatus"
                    :label="1"
                    >启用</el-radio
                  >
                  <el-radio
                    v-model="reviewStatus"
                    :label="0"
                    >禁用</el-radio
                  >
                </el-form-item>
  
                <el-form-item
                  v-if="reviewStatus == 1"
                  label="审查规则："
                  class="custom-label-weight"
                >
                  <span>轮次容量为</span>
                  <el-input
                    v-model="markRuleResult.reviewCapacity"
                    placeholder
                    @input="
                      (e) =>
                        (markRuleResult.reviewCapacity = $verifyInput(
                          e,
                          0,
                          100,
                          1
                        ))
                    "
                  ></el-input>
                  <span>&nbsp;，分差警示值为</span>
                  <el-input
                    v-model="markRuleResult.reviewThreshold"
                    placeholder
                    @input="
                      (e) =>
                        (markRuleResult.reviewThreshold = $verifyInput(
                          e,
                          0,
                          100,
                          1
                        ))
                    "
                  ></el-input>
                  <p>（最后一轮次考试份数没达到设置的容量时默认为一轮次）</p>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
  
        <el-row>
          <el-col :offset="1">
            <div id="customScoreRank">
              <el-form ref="markRuleResult" :model="markRuleResult">
                <el-form-item label="自定义分数段：" class="custom-label-weight">
                  <div>
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <i
                        class="el-icon-warning-outline"
                        style="position: relative; left: -8px"
                      ></i>
                      <div slot="content" class="tooltipBox">
                        <span>{{ customScoreRankDesc }}</span>
                      </div>
                    </el-tooltip>
                    <span>低分</span>
                    <el-input
                      placeholder
                      v-model="scoreParam.lowScore"
                      @input="lowScoreInput"
                    >
                      <template slot="append">%</template>
                    </el-input>
                    <span>及格</span>
                    <el-input
                      placeholder
                      v-model="scoreParam.passingScore"
                      @input="passingScoreInput"
                    >
                      <template slot="append">%</template>
                    </el-input>
                    <span>优分</span>
                    <el-input
                      placeholder
                      v-model="scoreParam.excellentScore"
                      @input="excellentScoreInput"
                    >
                      <template slot="append">%</template>
                    </el-input>
                    <span>高分</span>
                    <el-input
                      placeholder
                      v-model="scoreParam.highScore"
                      @input="highScoreInput"
                    >
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </el-form-item>
  
                <el-form-item label="AI实时评分：" class="custom-label-weight">
                  <el-switch v-model="markRuleResult.aiMarking"></el-switch>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
  
        <p class="badge-font-bold">评卷组</p>
        <el-row id="marking-setting-card">
          <el-col :span="9">
            <el-card shadow="always">
              <p class="un-selected-personnel-content">
                待选人员
                <span>共{{ markTeamList.length }}人</span>
              </p>
              <el-form :inline="true" ref="form" :model="queryUnChosenTeaParam">
                <el-form-item label="姓名" class="formItemBoxStyle">
                  <el-input
                    v-model="queryUnChosenTeaParam.queryName"
                    placeholder
                    @input="unChosenNameChange"
                    style="margin-left: 0; width: 60px"
                  ></el-input>
                </el-form-item>
  
                <el-form-item label="科目" class="formItemBoxStyle">
                  <el-select
                    v-model="queryUnChosenTeaParam.querySubjectType"
                    placeholder
                    class="select-box"
                    @change="unChosenSubjectChange"
                    style="margin-left: 0; width: 70px"
                  >
                    <el-option
                      v-for="subjectType in markRule.markSubjects"
                      :key="subjectType.subjectType"
                      :label="
                        subjectType.subjectType == 1
                          ? '物理'
                          : subjectType.subjectType == 2
                          ? '生物'
                          : '化学'
                      "
                      :value="subjectType.subjectType"
                    ></el-option>
                  </el-select>
                </el-form-item>
  
                <el-form-item label="区县" class="formItemBoxStyle">
                  <el-select
                    v-model="queryUnChosenTeaParam.querySysOrgAreaId"
                    placeholder
                    class="select-box"
                    @change="unChosenAreaChange"
                    style="margin-left: 0; width: 70px"
                  >
                    <el-option label="全部" value></el-option>
                    <el-option
                      v-for="area in orgAreaOptions"
                      :key="area.orgId"
                      :label="area.orgName"
                      :value="area.orgId"
                    ></el-option>
                  </el-select>
                </el-form-item>
  
                <el-form-item label="学校" class="formItemBoxStyle">
                  <el-select
                    v-model="queryUnChosenTeaParam.querySysOrgSchoolId"
                    placeholder
                    class="select-box"
                    @change="unChosenSchoolChange"
                    style="margin-left: 0; width: 70px"
                  >
                    <el-option label="全部" value></el-option>
                    <el-option
                      v-for="schoold in orgSchoolOptions"
                      :key="schoold.orgId"
                      :label="schoold.orgName"
                      :value="schoold.orgId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
  
              <el-row style>
                <el-table
                  v-loading="loading"
                  stripe
                  ref="unMarkTeacherTable"
                  :data="markTeamList"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="{
                    'text-align': 'center',
                    background: '#DFE6EC',
                  }"
                  :cell-style="{ 'text-align': 'center' }"
                  height="350"
                  @row-click="rowClickLeft"
                  :row-style="rowStyleLeft"
                  @selection-change="handleUnSelectionChange"
                >
                  >
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column
                    prop="nickName"
                    label="姓名"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="subjectType"
                    label="科目"
                    show-overflow-tooltip
                    width="48"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.subjectType == 0">未知</span>
                      <span v-if="scope.row.subjectType == 1">物理</span>
                      <span v-if="scope.row.subjectType == 2">生物</span>
                      <span v-if="scope.row.subjectType == 3">化学</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="sysOrgAreaName"
                    label="所属区县"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="sysOrgSchoolName"
                    label="所属学校"
                    show-overflow-tooltip
                  ></el-table-column>
                </el-table>
              </el-row>
            </el-card>
          </el-col>
  
          <el-col :span="2" class="arrow-content">
            <div>
              <el-button
                v-throttle="true"
                type="primary"
                @click="removeMarkTeacher"
              >
                <span
                  style="display: flex; align-items: center; line-height: 20px"
                >
                  <i class="el-icon-arrow-left" style="font-size: 20px"></i>
                  移除
                </span>
              </el-button>
            </div>
            <div>
              <el-button
                v-throttle="true"
                type="primary"
                @click="addMarkTeacher"
              >
                <span
                  style="display: flex; align-items: center; line-height: 20px"
                >
                  添加 <i class="el-icon-arrow-right" style="font-size: 20px"></i>
                </span>
              </el-button>
            </div>
          </el-col>
  
          <el-col :span="13">
            <div style="margin-bottom: 10px">
              <el-button
                v-throttle
                type="primary"
                size="medium"
                v-for="(val, i) in markingGroupList"
                :key="i"
                @click="markingGroupInfo(val)"
                >{{ val.content }}</el-button
              >
              <el-button
                v-throttle
                type="danger"
                size="medium"
                @click="removeMarkingGroup"
                >-</el-button
              >
              <el-button
                v-throttle
                type="primary"
                size="medium"
                @click="addMarkingGroup"
                >+</el-button
              >
            </div>
            <el-card shadow="always">
              <p class="selected-personnel-content">
                已选人员
                <span>共{{ filterChooseMarkTeacherList.length }}人</span>
              </p>
              <!--     <el-form :inline="true" ref="form" :model="queryChosenTeaParam" size="medium">
                <el-form-item label="姓名">
                  <el-input
                    v-model="queryChosenTeaParam.queryName"
                    placeholder
                    @input="filtrateChonseMarkTeacher"
                  ></el-input>
                </el-form-item>
  
                <el-form-item label="科目">
                  <el-select
                    v-model="queryChosenTeaParam.querySubjectType"
                    placeholder
                    class="select-box"
                    @change="filtrateChonseMarkTeacher"
                  >
                    <el-option label="全部" value></el-option>
                    <el-option
                      v-for="subjectType in markRule.markSubjects"
                      :key="subjectType.subjectType"
                      :label="subjectType.subjectType==1?'物理':subjectType.subjectType==2?'生物':'化学'"
                      :value="subjectType.subjectType"
                    ></el-option>
                  </el-select>
                </el-form-item>
  
                <el-form-item label="区县">
                  <el-select
                    v-model="queryChosenTeaParam.querySysOrgAreaId"
                    placeholder
                    class="select-box"
                    @change="filtrateChonseMarkTeacher"
                  >
                    <el-option label="全部" value></el-option>
                    <el-option
                      v-for="area in orgAreaOptions"
                      :key="area.orgId"
                      :label="area.orgName"
                      :value="area.orgId"
                    ></el-option>
                  </el-select>
                </el-form-item>
  
                <el-form-item label="学校">
                  <el-select
                    v-model="queryChosenTeaParam.querySysOrgSchoolId"
                    placeholder
                    class="select-box"
                    @change="filtrateChonseMarkTeacher"
                  >
                    <el-option label="全部" value></el-option>
                    <el-option
                      v-for="schoold in orgSchoolOptions"
                      :key="schoold.orgId"
                      :label="schoold.orgName"
                      :value="schoold.orgId"
                    ></el-option>
                  </el-select>
                </el-form-item>
  
                <el-form-item label="身份">
                  <el-select
                    v-model="queryChosenTeaParam.teacherType"
                    placeholder
                    class="select-box"
                    @change="filtrateChonseMarkTeacher"
                  >
                    <el-option label="全部" value></el-option>
                    <el-option
                      v-for="teacherType in markTeacherTypeOptions"
                      :key="teacherType.value"
                      :label="teacherType.label"
                      :value="teacherType.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>-->
              <el-row>
                <el-form :inline="true" :model="selectedFiltrationCondition">
                  <el-form-item label="姓名" class="formItemBoxStyle">
                    <el-input
                      v-model="selectedFiltrationCondition.queryName"
                      @change="selectedFiltrationFunc(1)"
                      style="width: 180px; margin-left: 0"
                    ></el-input>
                  </el-form-item>
  
                  <el-form-item label="科目" class="formItemBoxStyle">
                    <el-select
                      v-model="selectedFiltrationCondition.querySubjectType"
                      @change="selectedFiltrationFunc(4)"
                      placeholder
                    >
                      <el-option label="全部" :value="0"></el-option>
                      <el-option
                        v-for="sub in markRule.markSubjects"
                        :key="sub.subjectType"
                        :label="
                          sub.subjectType == 1
                            ? '物理'
                            : sub.subjectType == 2
                            ? '生物'
                            : '化学'
                        "
                        :value="sub.subjectType"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      icon="el-icon-refresh-left"
                      size="medium"
                      @click="selectedClearFilterBtn"
                    ></el-button>
                  </el-form-item>
                  <el-form-item style="float: right">
                    <el-button
                      type="primary"
                      size="medium"
                      @click="setSelectionMarkLeader"
                      >设为组长</el-button
                    >
                    <el-button
                      type="primary"
                      size="medium"
                      @click="setSelectionMarkTeacher"
                      >设为组员</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-row>
  
              <el-row>
                <el-table
                  v-loading="loading"
                  stripe
                  ref="markTeacherTable"
                  :data="filterChooseMarkTeacherList"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="{
                    'text-align': 'center',
                    background: '#DFE6EC',
                  }"
                  :cell-style="{ 'text-align': 'center' }"
                  height="305"
                  @row-click="rowClickRight"
                  :row-style="rowStyleRight"
                  @selection-change="handleSelectionChange"
                >
                  >
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column
                    prop="teacherName"
                    label="姓名"
                    show-overflow-tooltip
                    column-key
                    :filter-method="filterTeacherNameFunc"
                    :filtered-value="
                      !!selectedFiltrationCondition.queryName
                        ? [selectedFiltrationCondition.queryName]
                        : []
                    "
                  ></el-table-column>
                  <el-table-column
                    prop="teacherCode"
                    label="编码"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="account"
                    label="账号"
                    show-overflow-tooltip
                  ></el-table-column>
                  <!-- :filters="[
                      { text: '物理', value: 1 },
                      { text: '生物', value: 2 },
                      { text: '化学', value: 3 },
                    ]" -->
                  <el-table-column
                    prop="subjectType"
                    label="科目"
                    show-overflow-tooltip
                    column-key="subjectType"
                    width="48"
                    :filter-method="filterSubjectTypeFunc"
                    :filtered-value="
                      !!selectedFiltrationCondition.querySubjectType
                        ? [selectedFiltrationCondition.querySubjectType]
                        : []
                    "
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.subjectType == 0">未知</span>
                      <span v-if="scope.row.subjectType == 1">物理</span>
                      <span v-if="scope.row.subjectType == 2">生物</span>
                      <span v-if="scope.row.subjectType == 3">化学</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="orgAreaName"
                    label="所属区县"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="orgSchoolName"
                    label="所属学校"
                    show-overflow-tooltip
                  ></el-table-column>
                  <el-table-column
                    prop="teacherType"
                    label="身份"
                    show-overflow-tooltip
                    width="48"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.teacherType == 3"
                        style="color: #f59a23"
                        >组长</span
                      >
                      <span v-if="scope.row.teacherType == 4">组员</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    min-width="80px"
                    show-overflow-tooltip
                  >
                    <template slot-scope="scope">
                      <el-button
                        v-throttle
                        v-if="scope.row.teacherType == 3"
                        type="text"
                        size="mini"
                        @click.native.stop="setMarkTeacher(scope.row)"
                        >设为组员</el-button
                      >
                      <el-button
                        v-throttle
                        v-if="scope.row.teacherType == 4"
                        type="text"
                        size="mini"
                        @click.native.stop="setMarkLeader(scope.row)"
                        >设为组长</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <div id="footer">
          <el-button v-throttle type="primary" @click="save"
            >保存</el-button
          >
          <el-button v-throttle type="danger" @click="handleCancel"
            >取 消</el-button
          >
        </div>
      </el-card>
    </div>
  </template>
  <script>
  import {
    getMarkRuleListApi,
    updateMarkRuleApi,
    getChosenTeacherListApi,
    getUnChosenTeacherListApi,
    addMarkTeacherApi,
    removeMarkTeacherApi,
    updateMarkTeacherTypeApi,
    getUserRoleListApi,
    updateExamedMarkRuleApi
  } from '@/api/marking/markingSetting.js'
  import {
    getOrgAreaListApi,
    getOrgSchoolListApi,
  } from '@/api/user/userManage.js'
  import { isEmpty, formatDate } from '@/utils/util'
  export default {
    components: {},
    name: 'markingSetting',
    data() {
      return {
        // 是否已开启评卷
        openExam: true,
        // 是否子页面进入
        mainExam: true,
        mainTitle: '',
        subTitle: '',
        dialogVisible: true,
        loading: false,
        //移除按钮是否禁用
        removeDisabled: true,
        //添加按钮是否禁用
        appendDisabled: true,
        //待选阅卷老师列表
        unMarkTeacherList: [],
        //已选阅卷老师列表
        markTeacherList: [],
        orgAreaOptions: [],
        orgSchoolOptions: [],
        //待选人员
        unSelectedTeacherList: [],
        //已选人员
        selectedTeacherList: [],
        //考试信息
        examInfo: {},
        //评卷规则信息
        markRule: {},
        markRuleResult: {
          markType: '',
          sceneMarkNum: '',
          markPass: '',
          markRule: '',
          markThreshold: '',
          // reviewStatus: 0,
          reviewCapacity: '',
          reviewThreshold: '',
          subjectTypes: [],
          markLeaderIds: [],
          markMemberIds: [],
          matchRule: 1,
          aiMarking: false,
        },
        scoreParam: {
          lowScore: 40,
          passingScore: 60,
          excellentScore: 80,
          highScore: 90,
        },
        reviewStatus: 0,
        markSubjectTypeOptions: [
          { label: '未知', value: 0 },
          { label: '物理', value: 1 },
          { label: '生物', value: 2 },
          { label: '化学', value: 3 },
        ],
        markTeacherTypeOptions: [
          { label: '组长', value: 3 },
          { label: '组员', value: 4 },
        ],
        markTeamList: [],
        //已选阅卷教师
        chooseMarkTeacherList: [],
        filterChooseMarkTeacherList: [],
        //已选组长
        chooseMarkLeaderIds: [],
        //已选组员
        chooseMarkTeacherIds: [],
        queryUnChosenTeaParam: {
          excludeUserIdList: [],
          queryName: '',
          querySubjectType: 0,
          querySysOrgAreaId: '',
          querySysOrgSchoolId: '',
        },
        queryChosenTeaParam: {
          queryName: '',
          querySubjectType: 0,
          querySysOrgAreaId: '',
          querySysOrgSchoolId: '',
          teacherType: '',
        },
        moreRuleDesc: `多评制
            先设定一个分差阈值，每份考卷随机发放给两名评卷老师分别打分，
            比较二者评分分差，如果小于等于阈值，则取二者平均值作为考生的得分；
            如果分差大于阈值，则将此考卷发放给第三名老师，评出第三个评分，
            与之前两者进行两两分差比对，如果某比对组的分差小于等于阈值，
            则取该比对组的平均分作为考生的得分。
            如果两个比对组的分差都小于等于阈值则会取较高的平均值作为考生得分。
            如果两个比对组的分差都大于阈值，则直接把该考卷交与评卷组组长，
            组长的评分即为考生得分。`,
        reviewStatusDesc: `回评审查
            先设定轮次容量为n，分差警示值为m。每名评卷老师评卷时，
            每评n份试卷即为完成一轮次，
            系统将会随机抽出此轮次中的一份试卷重新发放给该老师二次评分。
            一旦出现二次评分与一次评分的差值超过分差警示值m，
            则该轮次的n份试卷要全部重新评分。`,
        customScoreRankDesc: `自定义分数段
            1、假设总分为10分，
            低分设置为40%，则分数在4分以下的为低分；
            及格设置为60%，则分数在6分及以上的为及格；
            优分设置为80%，则分数在8分及以上的为优分；
            高分设置为90%，则分数在9分及以上的为高分；
            设置完成之后成绩统计会按照设置的分数段自动统计每个分数段的人数以及占比；
            2、设置规则：低分<及格<优分<高分。`,
        markTypeOptions: [
          { label: '考后评分', value: 1 },
          { label: '现场评分', value: 2 },
          { label: '直播评分', value: 3 },
        ],
        markMatchRuleOptions: [
          { label: '不限制', value: 1 },
          { label: '禁止匹配同区', value: 2 },
          { label: '禁止匹配同校', value: 3 },
        ],
        input2: '',
        markingGroupList: [
          { index: 1, content: '阅卷组1', markingGroupSelectedList: [] },
        ],
        markingGroupIndex: 0,
        // 已选人员过滤条件
        selectedFiltrationCondition: {
          queryName: '',
          querySubjectType: 0,
        },
      }
    },
    watch: {},
    mounted() {
      let markRuleExamInfo = JSON.parse(this.$route.query.markRuleExamInfo)
      // 进入页面
      this.mainExam = !!markRuleExamInfo.mainExam
      this.openExam = markRuleExamInfo.openExam
      if (!this.openExam) {
        this.mainExam = false
      }
      if (!isEmpty(markRuleExamInfo)) {
        this.examInfo = markRuleExamInfo
      }
      if (!isEmpty(markRuleExamInfo.examName)) {
        this.mainTitle = markRuleExamInfo.examName
      }
      if (!isEmpty(markRuleExamInfo.examStartTime)) {
        this.subTitle = `本场考试将于${formatDate(
          new Date(markRuleExamInfo.examStartTime),
          'yyyy-MM-dd hh:mm:ss'
        )} 开始`
      }
      this.init()
    },
    methods: {
      init() {
        this.initMarkRule()
        this.getOrgAreaList()
      },
      initMarkRule() {
        if (isEmpty(this.examInfo.examId)) {
          return
        }
        this.getMarkRuleList()
      },
      getMarkRuleList() {
        if (isEmpty(this.examInfo.examId)) {
          return
        }
        getMarkRuleListApi(this.examInfo.examId)
          .then((res) => {
            if (res.success) {
              if (!isEmpty(res.data)) {
                this.markRule = {}
                this.markRule = res.data
                //已选阅卷教师
                this.chooseMarkTeacherList = []
                this.chooseMarkTeacherList = this.markRule.markTeachers
                this.filterChooseMarkTeacherList = []
                this.filterChooseMarkTeacherList = this.markRule.markTeachers
                this.markingGroupList[0].markingGroupSelectedList =
                  this.markRule.markTeachers
                //默认选中第一个学科
                this.queryUnChosenTeaParam.querySubjectType =
                  this.markRule.markSubjects[0].subjectType
                this.queryChosenTeaParam.querySubjectType =
                  this.markRule.markSubjects[0].subjectType
                this.chooseMarkLeaderIds = []
                this.chooseMarkTeacherIds = []
                this.markRule.markTeachers.forEach((item) => {
                  if (item.teacherType == 3) {
                    this.chooseMarkLeaderIds.push(item.teacherId)
                  } else {
                    this.chooseMarkTeacherIds.push(item.teacherId)
                  }
                })
                this.getUserRoleList()
              }
              if (res.data.markSubjects.length > 0) {
                this.markRuleResult = {}
                this.markRuleResult = JSON.parse(
                  JSON.stringify(res.data.markSubjects[0])
                )
                if (isEmpty(this.markRuleResult.markRule)) {
                  this.markRuleResult.markType = 1
                }
                if (this.markRuleResult.lowScore != 0) {
                  this.scoreParam.lowScore = this.markRuleResult.lowScore
                }
                if (this.markRuleResult.passingScore != 0) {
                  this.scoreParam.passingScore = this.markRuleResult.passingScore
                }
                if (this.markRuleResult.excellentScore != 0) {
                  this.scoreParam.excellentScore =
                    this.markRuleResult.excellentScore
                }
                if (this.markRuleResult.highScore != 0) {
                  this.scoreParam.highScore = this.markRuleResult.highScore
                }
                this.reviewStatus = this.markRuleResult.reviewStatus
                this.$set(this.markRuleResult, 'examId', '')
                this.$set(this.markRuleResult, 'subjectTypes', [])
              }
            } else {
              this.msgError(res.msg)
            }
          })
          .catch(() => {})
      },
      //获取阅卷教师
      getUserRoleList() {
        this.queryUnChosenTeaParam.excludeUserIdList = []
        this.chooseMarkTeacherList.forEach((item) => {
          this.queryUnChosenTeaParam.excludeUserIdList.push(item.teacherId)
        })
        this.loading = true
        getUserRoleListApi(this.queryUnChosenTeaParam)
          .then((res) => {
            if (res.success) {
              this.markTeamList = []
              this.markTeamList = res.data
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      },
      //获取待选教师列表
      getUnChosenTeacherList() {
        getUnChosenTeacherListApi(this.queryUnChosenTeaParam)
          .then((res) => {
            if (res.success) {
              this.unMarkTeacherList = []
              this.unMarkTeacherList = res.data
            }
          })
          .catch(() => {})
      },
      //获取已选教师列表
      getChosenTeacherList() {
        getChosenTeacherListApi(this.queryChosenTeaParam)
          .then((res) => {
            if (res.success) {
              this.markTeacherList = []
              this.markTeacherList = res.data
            }
          })
          .catch(() => {})
      },
      //获取筛选区县列表
      getOrgAreaList() {
        getOrgAreaListApi()
          .then((res) => {
            if (res.success) {
              this.orgAreaOptions = []
              res.data.childrenOrg.forEach((item) => {
                if (isEmpty(item)) {
                  return
                }
                this.orgAreaOptions.push(item)
              })
            }
          })
          .catch(() => {})
      },
      unChosenNameChange(name) {
        this.queryUnChosenTeaParam.queryName = name
        this.getUserRoleList()
      },
      unChosenSubjectChange(subjectId) {
        this.queryUnChosenTeaParam.querySubjectType = subjectId
        this.getUserRoleList()
      },
      unChosenAreaChange(orgId) {
        this.queryUnChosenTeaParam.querySysOrgAreaId = orgId
        this.queryUnChosenTeaParam.querySysOrgSchoolId = ''
        this.getUserRoleList()
        this.getOrgSchoolList(orgId)
      },
      unChosenSchoolChange(schoolId) {
        this.queryUnChosenTeaParam.querySysOrgSchoolId = schoolId
        this.getUserRoleList()
      },
      chosenAreaChange(orgId) {
        this.queryChosenTeaParam.querySysOrgSchoolId = ''
        this.filtrateChonseMarkTeacher()
        this.getOrgSchoolList(orgId)
      },
      getOrgSchoolList(orgId) {
        if (isEmpty(orgId)) {
          this.orgSchoolOptions = []
          return
        }
        getOrgSchoolListApi(0, 1, orgId)
          .then((res) => {
            if (res.success) {
              this.orgSchoolOptions = []
              this.orgSchoolOptions = res.data.childrenOrg
            }
          })
          .catch(() => {})
      },
      filtrateChonseMarkTeacher() {
        this.filterChooseMarkTeacherList = []
        let list = this.chooseMarkTeacherList
        list.forEach((item) => {
          if (
            item.teacherName.indexOf(this.queryChosenTeaParam.queryName) != -1
          ) {
            this.filterChooseMarkTeacherList.push(item)
          }
        })
      },
      setSelectionMarkLeader() {
        if (this.selectedTeacherList <= 0) {
          this.msgWarning('请选择至少一行')
          return
        }
        this.batchUpdateMarkTeacherType(3, this.selectedTeacherList)
      },
      setSelectionMarkTeacher() {
        if (this.selectedTeacherList <= 0) {
          this.msgWarning('请选择至少一行')
          return
        }
        this.batchUpdateMarkTeacherType(4, this.selectedTeacherList)
      },
      //设为组长
      setMarkLeader(row) {
        if (row.teacherType == 3) {
          this.msgWarning('已经是组长了')
          this.$refs.markTeacherTable.clearSelection()
          return
        }
        this.updateMarkTeacherType(3, row)
      },
      //设为组员
      setMarkTeacher(row) {
        if (row.teacherType == 4) {
          this.msgWarning('已经是组员了')
          this.$refs.markTeacherTable.clearSelection()
          return
        }
        this.updateMarkTeacherType(4, row)
      },
      updateMarkTeacherType(teacherType, row) {
        row.teacherType = teacherType
      },
      batchUpdateMarkTeacherType(teacherType, examMarkTeacherIds) {
        examMarkTeacherIds.forEach((item) => {
          item.teacherType = teacherType
        })
      },
      rowClickLeft(row, column, event) {
        let refsElTable = this.$refs.unMarkTeacherTable // 获取表格对象
        let findRow = this.unSelectedTeacherList.find(
          (c) => c.rowIndex == row.rowIndex
        ) //找到选中的行
        if (findRow) {
          refsElTable.toggleRowSelection(row, false) //如过重复选中，则取消选中
          return
        }
        refsElTable.toggleRowSelection(row, true) // 实现选中行中选中事件
      },
      rowStyleLeft({ row, rowIndex }) {
        Object.defineProperty(row, 'rowIndex', {
          //给每一行添加不可枚举属性rowIndex来标识当前行
          value: rowIndex,
          writable: true,
          enumerable: false,
        })
      },
      handleUnSelectionChange(val) {
        this.unSelectedTeacherList = []
        this.unSelectedTeacherList = val
        if (val.length > 0) {
          this.appendDisabled = false
        } else {
          this.appendDisabled = true
        }
      },
      rowClickRight(row, column, event) {
        let refsElTable = this.$refs.markTeacherTable // 获取表格对象
        let findRow = this.selectedTeacherList.find(
          (c) => c.rowIndex == row.rowIndex
        ) //找到选中的行
        if (findRow) {
          refsElTable.toggleRowSelection(row, false) //如过重复选中，则取消选中
          return
        }
        refsElTable.toggleRowSelection(row, true) // 实现选中行中选中事件
      },
      rowStyleRight({ row, rowIndex }) {
        Object.defineProperty(row, 'rowIndex', {
          //给每一行添加不可枚举属性rowIndex来标识当前行
          value: rowIndex,
          writable: true,
          enumerable: false,
        })
      },
      handleSelectionChange(val) {
        this.selectedTeacherList = []
        this.selectedTeacherList = val
        if (val.length > 0) {
          this.removeDisabled = false
        } else {
          this.removeDisabled = true
        }
      },
      //添加阅卷老师
      addMarkTeacher() {
        const markTeamListChooseIndexArray = []
        for (var i = 0; i < this.markTeamList.length; i++) {
          for (var j = 0; j < this.unSelectedTeacherList.length; j++) {
            if (
              this.markTeamList[i].userId == this.unSelectedTeacherList[j].userId
            ) {
              let markTeacher = {
                teacherCode: this.markTeamList[i].userCode,
                account: this.markTeamList[i].account,
                teacherName: this.markTeamList[i].nickName,
                subjectType: this.markTeamList[i].subjectType,
                orgAreaId: this.markTeamList[i].sysOrgAreaId,
                orgAreaName: this.markTeamList[i].sysOrgAreaName,
                orgSchoolId: this.markTeamList[i].sysOrgSchoolId,
                orgSchoolName: this.markTeamList[i].sysOrgSchoolName,
                teacherId: this.markTeamList[i].userId,
                teacherType: 4,
              }
              this.chooseMarkTeacherList.push(markTeacher)
              // this.markingGroupList[this.markingGroupIndex].markingGroupSelectedList.push(markTeacher);
              markTeamListChooseIndexArray.push(i)
              // this.markTeamList.splice(i, 1);
            }
          }
        }
        // 倒叙，从后面删除
        markTeamListChooseIndexArray.sort((x, y) => y - x)
        for (let i = 0; i < markTeamListChooseIndexArray.length; i++) {
          this.markTeamList.splice(markTeamListChooseIndexArray[i], 1)
        }
        //清空table 已选项
        this.$refs.unMarkTeacherTable.clearSelection()
      },
      //移除阅卷老师
      removeMarkTeacher() {
        for (var i = 0; i < this.chooseMarkTeacherList.length; i++) {
          for (var j = 0; j < this.selectedTeacherList.length; j++) {
            if (
              this.chooseMarkTeacherList[i].teacherId ==
              this.selectedTeacherList[j].teacherId
            ) {
              this.chooseMarkTeacherList.splice(i, 1)
            }
          }
        }
        this.getUserRoleList()
        //清空table 已选项
        this.$refs.markTeacherTable.clearSelection()
      },
      resetQueryParam(text) {
        if (text == 'unChosen') {
          this.queryUnChosenTeaParam.orgAreaId = ''
          this.queryUnChosenTeaParam.orgSchoolId = ''
          this.queryUnChosenTeaParam.subjectType = ''
          this.queryUnChosenTeaParam.teacherName = ''
          this.queryUnChosenTeaParam.teacherType = ''
        } else {
          this.queryChosenTeaParam.orgAreaId = ''
          this.queryChosenTeaParam.orgSchoolId = ''
          this.queryChosenTeaParam.subjectType = ''
          this.queryChosenTeaParam.teacherName = ''
          this.queryChosenTeaParam.teacherType = ''
        }
      },
      decideSceneMarkNum() {
        if (!isEmpty(this.markRuleResult.sceneMarkNum)) {
          switch (this.markRuleResult.markType) {
            case 2:
              if (this.markRuleResult.sceneMarkNum > 4) {
                this.markRuleResult.sceneMarkNum = 4
              }
              break
            case 3:
              if (this.markRuleResult.sceneMarkNum > 2) {
                this.markRuleResult.sceneMarkNum = 2
              }
              break
          }
        }
      },
      save() {
        if (isEmpty(this.markRuleResult.markType)) {
          this.msgWarning('请选择评卷方式')
          return
        }
        if (this.markRuleResult.markType == 3) {
          if (!this.markRuleResult.sceneMarkNum) {
            this.msgWarning('老师评阅的考生数不能为空')
            return
          }
        }
        if (this.markRuleResult.markType == 2) {
          if (!this.markRuleResult.sceneMarkNum) {
            this.msgWarning('老师评阅的考生数不能为空')
            return
          }
        }
        /* if (!this.markRuleResult.markPass) {
          this.msgWarning('及格分数不能为空');
          return;
        } */
        if (this.markRuleResult.markType == 1) {
          if (isEmpty(this.markRuleResult.markRule)) {
            this.msgWarning('请选择评分规则')
            return
          }
          /*   if (this.markRuleResult.markPass > this.markRule.markPassMax) {
              this.msgWarning(`及格分数不能大于 ${this.markRule.markPassMax}`);
              return;
            } */
          if (this.markRuleResult.markRule == 2) {
            if (!this.markRuleResult.markThreshold) {
              this.msgWarning('分差阈值不能为空')
              return
            }
          }
          if (isEmpty(this.reviewStatus)) {
            this.msgWarning('请选择审查规则')
            return
          }
          if (this.reviewStatus == 1) {
            if (!this.markRuleResult.reviewCapacity) {
              this.msgWarning('轮次容量不能为空')
              return
            }
            if (!this.markRuleResult.reviewThreshold) {
              this.msgWarning('分差警示值不能为空')
              return
            }
          }
        }
        if (isEmpty(this.scoreParam.lowScore)) {
          this.msgWarning('低分不能为空')
          return
        }
        if (this.scoreParam.lowScore <= 0) {
          this.msgWarning('低分必须大于0')
          return
        }
        if (isEmpty(this.scoreParam.passingScore)) {
          this.msgWarning('及格分不能为空')
          return
        }
        if (isEmpty(this.scoreParam.excellentScore)) {
          this.msgWarning('优分不能为空')
          return
        }
        if (isEmpty(this.scoreParam.highScore)) {
          this.msgWarning('高分不能为空')
          return
        }
        if (this.scoreParam.highScore >= 100) {
          this.msgWarning('高分只能低于100')
          return
        }
        if (
          this.scoreParam.lowScore >= this.scoreParam.passingScore ||
          this.scoreParam.passingScore >= this.scoreParam.excellentScore ||
          this.scoreParam.excellentScore >= this.scoreParam.highScore
        ) {
          this.msgWarning('请按规则填写自定义分数段,低分<及格<优分<高分')
          return
        }
        // if (!isEmpty(this.markRuleResult.markSubjectId)) {
        // this.markRuleResult.subjectTypes.push(this.markRuleResult.markSubjectId);
        // }
        this.markRuleResult.examId = this.markRule.examId
        this.markRuleResult.markRuleId = this.markRule.markRuleId
        this.markRuleResult.reviewStatus = this.reviewStatus
        this.markRuleResult.markLeaderIds = []
        this.markRuleResult.markMemberIds = []
        this.markRuleResult.lowScore = Number(this.scoreParam.lowScore)
        this.markRuleResult.passingScore = Number(this.scoreParam.passingScore)
        this.markRuleResult.excellentScore = Number(
          this.scoreParam.excellentScore
        )
        this.markRuleResult.highScore = Number(this.scoreParam.highScore)
        if (this.markingGroupList[0].markingGroupSelectedList.length > 0) {
          this.chooseMarkTeacherList =
            this.markingGroupList[0].markingGroupSelectedList
        }
        this.chooseMarkTeacherList.forEach((item) => {
          if (item.teacherType == 3) {
            this.markRuleResult.markLeaderIds.push(item.teacherId)
          } else {
            this.markRuleResult.markMemberIds.push(item.teacherId)
          }
        })
        const loading = this.globalLoading('正在保存...')
        updateExamedMarkRuleApi(this.markRuleResult)
          .then((res) => {
            loading.close()
            if (res.success) {
              this.msgSuccess('保存成功')
              this.dialogVisible = false
              this.clearMarkRule()
              this.$router.push({ name: 'markingManage' })
            } else {
              if (res.code == '505') {
                this.msgError('已经开始评卷了,无法修改阅卷规则')
              } else {
                this.msgError(res.msg)
              }
            }
          })
          .catch(() => {
            loading.close()
          })
      },
      handleClose() {
        this.dialogVisible = false
        this.clearMarkRule()
        this.$router.push({ name: 'markingManage' })
      },
      handleCancel() {
        this.dialogVisible = false
        this.clearMarkRule()
        this.$router.push({ name: 'markingManage' })
      },
      clearMarkRule() {
        this.examInfo = {}
        this.markRule = {}
      },
      markTypeChange() {
        this.markRuleResult.sceneMarkNum = ''
      },
      lowScoreInput() {
        this.scoreParam.lowScore = this.$verifyInput(
          this.scoreParam.lowScore,
          0,
          100
        )
        this.scoreParam.lowScore = Number(this.scoreParam.lowScore)
      },
      passingScoreInput() {
        this.scoreParam.passingScore = this.$verifyInput(
          this.scoreParam.passingScore,
          0,
          100
        )
        this.scoreParam.passingScore = Number(this.scoreParam.passingScore)
      },
      excellentScoreInput() {
        this.scoreParam.excellentScore = this.$verifyInput(
          this.scoreParam.excellentScore,
          0,
          100
        )
        this.scoreParam.excellentScore = Number(this.scoreParam.excellentScore)
      },
      highScoreInput() {
        this.scoreParam.highScore = this.$verifyInput(
          this.scoreParam.highScore,
          0,
          100
        )
        this.scoreParam.highScore = Number(this.scoreParam.highScore)
      },
      markingGroupInfo(val) {
        this.markingGroupIndex = val.index - 1
        this.filterChooseMarkTeacherList = []
        this.filterChooseMarkTeacherList = val.markingGroupSelectedList
      },
      addMarkingGroup() {
        let length = this.markingGroupList.length
        if (length >= 4) {
          this.msgWarning('阅卷组不能超出4个')
          return
        }
        let lastIndex = this.markingGroupList[length - 1].index
        this.markingGroupList.push({
          index: lastIndex + 1,
          content: `阅卷组${lastIndex + 1}`,
          markingGroupSelectedList: [],
        })
      },
      removeMarkingGroup() {
        let length = this.markingGroupList.length
        let lastIndex = this.markingGroupList[length - 1].index - 1
        if (lastIndex == 0) {
          this.msgWarning('阅卷组不得少于1个')
          return
        }
        this.markingGroupList.splice(lastIndex, 1)
      },
      // 选择人员过滤触发
      selectedFiltrationFunc(num) {
        this.$nextTick(() => {
          const refs = this.$refs.markTeacherTable
          var column = refs.columns[num]
          // 重新定义需要筛选的数组
          switch (num) {
            case 1:
              if (this.selectedFiltrationCondition.queryName) {
                column.filteredValue = [
                  this.selectedFiltrationCondition.queryName,
                ]
              } else {
                column.filteredValue = []
              }
              break
  
            default:
              if (this.selectedFiltrationCondition.querySubjectType) {
                column.filteredValue = [
                  this.selectedFiltrationCondition.querySubjectType,
                ]
              } else {
                column.filteredValue = []
              }
              break
          }
          // 重新加载该列
          refs.store.commit('filterChange', {
            column,
            values: column.filteredValue,
            silent: true,
          })
        })
      },
      // 选择人员科目
      filterSubjectTypeFunc(value, row, column) {
        return row.subjectType === value
      },
      // 选择人员姓名
      filterTeacherNameFunc(value, row, column) {
        if (!!~row.teacherName.indexOf(value)) return true
      },
      // 清空筛选
      selectedClearFilterBtn() {
        this.selectedFiltrationCondition.queryName = ''
        this.selectedFiltrationCondition.querySubjectType = 0
        console.log('this.$refs.markTeacherTable', this.$refs.markTeacherTable)
        // this.$refs.markTeacherTable.clearFilter()
        this.selectedFiltrationFunc(1)
        this.selectedFiltrationFunc(4)
      },
    },
  }
  </script>
  <style lang="scss" scoped>
  ::v-deep .el-card__body {
    padding: 10px;
  }
  
  #marking-setting-content {
    padding-bottom: 20px;
  }
  
  #marking-setting-card {
    height: 500px;
    margin-bottom: 20px;
  }
  
  .badge-font-bold {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  #title-box {
    padding: 15px 10px;
    background-color: rgb(242, 243, 247);
  }
  
  .main-title {
    font-size: 20px;
    font-weight: bold;
  }
  
  .sub-title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 30px;
  }
  
  .el-input {
    width: 79px;
    margin-left: 10px;
  }
  
  #footer {
    text-align: center;
    margin-top: 50px;
  }
  
  .un-selected-personnel-content,
  .selected-personnel-content {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #dfe6ec;
    padding: 10px 0;
  }
  
  .un-selected-personnel-content > span,
  .selected-personnel-content > span {
    float: right;
    padding-right: 10px;
  }
  
  .arrow-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  
  .arrow-content div:first-child {
    margin-bottom: 20px;
  }
  
  .arrow-content div {
    text-align: center;
  }
  
  .select-box {
    width: 90px;
  }
  
  ::v-deep .custom-label-weight > .el-form-item__label {
    font-weight: bold;
  }
  
  .tooltipBox {
    width: 320px;
    font-size: 14px;
  }
  
  ::v-deep .el-input__inner {
    text-align: center;
  }
  
  #customScoreRank span:first-child {
    margin-left: 0px;
  }
  
  #customScoreRank span {
    margin-left: 10px;
  }
  
  #customScoreRank ::v-deep .el-input__inner {
    width: 50px;
    padding: 0 10px;
  }
  
  #customScoreRank ::v-deep .el-input-group__append {
    padding: 0 5px;
    position: relative;
    left: -5px;
  }
  
  ::v-deep .el-table__column-filter-trigger {
    display: none;
    position: absolute;
    top: 0;
    z-index: -999;
  }
  </style>
  <style>
  .el-table-filter {
    display: none;
  }
  </style>
  